<script setup lang="ts">
const { currentPalette } = useTheme();
const { isMobile } = useUserDevice();

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const { y } = useWindowScroll();

const isVisible = computed(() => y.value > 1920);
</script>

<template>
  <button
    v-if="isMobile"
    @click="scrollToTop"
    class="app-scroll fixed bottom-32 left-0 w-11 h-12 flex items-center justify-center rounded-e-full text-white z-40 py-3 pl-2 pe-3 transition-[opacity,visibility] duration-300"
    :class="[
      currentPalette.bg,
      {
        'opacity-100 visible': isVisible,
        'opacity-0 invisible': !isVisible,
      },
    ]"
  >
    <Icon name="ns:arrow-up-from-dot" class="w-6 h-6" />
  </button>
</template>
